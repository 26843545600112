import { Box, Button, Flex, Text } from 'theme-ui';
import { getBackgroundColor, getTextColor } from '../helper';
import React from 'react';

export type ButtonProps = { title: string; theme: string; id: string };

export const ButtonGroup = ({
  buttons,
  activeIndex,
  setActiveIndex,
}: {
  buttons: ButtonProps[];
  activeIndex: number;
  setActiveIndex: (ind: number) => void;
}) => {
  return (
    <Flex sx={{ flexDirection: 'row', width: '100%' }}>
      {buttons.map(({ title, theme, id }, ind) => (
        <Button
          key={id}
          onClick={() => setActiveIndex(ind)}
          sx={{
            flex: '1 1 0%',
            paddingY: '20px',
            paddingX: '24px',
            color: getTextColor(theme),
            backgroundColor: getBackgroundColor(theme),
            ':hover': {
              backgroundColor: getBackgroundColor(theme),
            },
            boxShadow:
              activeIndex === ind
                ? '0px 22px 38px 15px rgba(0, 0, 0, 0.13)'
                : undefined,
            border: 'none',
            borderRadius: '12px 12px 0px 0px',
            position: 'relative',
          }}
        >
          {activeIndex === ind ? (
            <Box
              sx={{
                height: '4px',
                width: '100%',
                position: 'absolute',
                backgroundColor: '#107EFF',
                bottom: '0px',
              }}
            />
          ) : null}
          <Text
            sx={{
              fontWeight: 800,
              fontSize: '17px',
              lineHeight: '21px',
              textTransform: 'none',
            }}
          >
            {title}
          </Text>
        </Button>
      ))}
    </Flex>
  );
};
