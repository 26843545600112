import React from 'react';

import { ProductPricingCardProps } from '../../types';
import { Box, Flex, Text } from 'theme-ui';

import { FeatureSection } from './FeatureSection';
import { CallToAction } from '@sprinklr/shared-lib';

import { getBillingInfoColor } from '../../helper';

export const ProductCardBody = (props: ProductPricingCardProps) => {
  const { featureSection, theme, cta, pricingInfo } = props;
  return (
    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
      <Text
        as="div"
        sx={{
          flex: '0 0 auto',
          fontSize: pricingInfo.price ? '64px' : ['30px', '30px', '36px'],
          lineHeight: '72px',
          fontWeight: 800,
          color: pricingInfo.price ? '#107EFF' : '#000000',
        }}
      >
        {pricingInfo.price ?? pricingInfo.enterpriseTitle}
      </Text>
      <Text
        as="div"
        sx={{
          flex: '0 0 auto',
          marginTop: '8px',
          fontSize: ['16px', '16px', '17px'],
          lineHeight: ['18px', '18px', '19px'],
          fontWeight: 500,
          color: getBillingInfoColor(props.theme),
        }}
      >
        {pricingInfo.billingInfo}
      </Text>
      <Flex sx={{ marginTop: ['36px', '36px', '40px'], flex: '0 0 auto' }}>
        <CallToAction {...cta} />
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '32px',
          flex: '1 1 0%',
          marginTop: '20px',
        }}
      >
        {featureSection.map(sec => (
          <FeatureSection
            key={sec.title}
            {...sec}
            theme={theme}
          ></FeatureSection>
        ))}
      </Flex>
    </Box>
  );
};
