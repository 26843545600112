import React from 'react';

import { Box, Flex, Text } from 'theme-ui';
import { FeatureSection as FeatureSectionProps, EXCLUSIVE } from '../../types';

import { getTextColor } from '../../helper';

import TickIcon from '@sprinklr/shared-lib/assets/svgs/tick-color.svg';
import SprinklrExclusive from '@sprinklr/shared-lib/assets/svgs/sprinklr-exclusive.svg';

export const FeatureSection = ({
  theme,
  title,
  productFeatures,
}: FeatureSectionProps & { theme: string }) => {
  return (
    <Flex sx={{ flexDirection: 'column', gap: '12px' }}>
      <Text
        sx={{
          fontSize: '13px',
          fontWeight: 'bold',
          lineHeight: '14px',
          color: getTextColor(theme),
        }}
      >
        {title}
      </Text>
      <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
        {productFeatures.map((item, index) => (
          <Flex key={item.name} sx={{ flexDirection: 'column', gap: '8px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TickIcon
                style={{
                  height: '12px',
                  width: '12px',
                  fill: '#107EFF',
                  flex: '0 0 auto',
                  alignSelf: 'flex-start',
                  marginTop: '1px',
                }}
              />
              <Box
                as="span"
                sx={{ flex: '1 1 0%', marginLeft: '8px', lineHeight: '14px' }}
              >
                <Text
                  as="span"
                  sx={{
                    fontSize: '13px',
                    lineHeight: '14px',
                    fontWeight: 'normal',
                    color: getTextColor(theme),
                  }}
                >
                  {item.name}
                </Text>
                {item.variant === EXCLUSIVE ? (
                  <Box
                    as="span"
                    sx={{ position: 'relative', marginLeft: '2px' }}
                  >
                    <SprinklrExclusive
                      style={{
                        position: 'absolute',
                        height: '14px',
                        top: '4px',
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
            {index !== productFeatures.length - 1 ? (
              <Box
                sx={{
                  width: '85%',
                  border:
                    theme === 'dark'
                      ? '0.5px solid #4B484E'
                      : '0.5px solid #ACABAD',
                }}
              />
            ) : null}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
