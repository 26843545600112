import React from 'react';

import { Box, Grid, Flex, Text } from 'theme-ui';
import { ProductPricingCardProps } from '../../types';

import { getTextColor } from '../../helper';
import { ProductCardBody } from './ProductCardBody';

export const ProductCard = ({
  theme,
  title,
  featureSection,
  cta,
  pricingInfo,
}: ProductPricingCardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingY: '24px',
        backgroundColor: theme === 'dark' ? '#1A1A1A' : '#ffffff',
        boxShadow: '0px 1.409px 15px rgba(20, 20, 43, 0.1)',
        borderRadius: '16px',
        border: '1px solid #EFF0F6',
        paddingLeft: '36px',
        paddingRight: '16px',
        maxWidth: '420px',
      }}
    >
      <Text
        sx={{
          fontSize: ['17px', '17px', '22px'],
          fontWeight: 800,
          lineHeight: ['21px', '21px', '26px'],
          color: getTextColor(theme),
          flex: '0 0 auto',
        }}
      >
        {title}
      </Text>
      <Box sx={{ marginTop: '12px', flex: '1 1 0%', display: 'flex' }}>
        <ProductCardBody
          title={title}
          featureSection={featureSection}
          theme={theme}
          cta={cta}
          pricingInfo={pricingInfo}
        />
      </Box>
    </Box>
  );
};
