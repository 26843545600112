import React, { useState } from 'react';
import { Flex, Text, Box, Button } from 'theme-ui';

import { ProductCardBody } from './components/productCard/ProductCardBody';
import { ButtonGroup } from './components/ButtonGroup';
import { ProductCard } from './components/productCard';

import { ProductPricingCardProps } from './types';
import { getBackgroundColor } from './helper';

const MobileVariant = ({
  productCards,
}: {
  productCards: ProductPricingCardProps[];
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  if (productCards.length === 1) {
    return <ProductCard {...productCards[0]} />;
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        boxShadow: '0px 22px 38px 15px rgba(0, 0, 0, 0.13)',
        borderRadius: '12px 12px 28px 28px',
      }}
    >
      <ButtonGroup
        buttons={productCards}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '32px',
          paddingY: '24px',
          paddingX: '20px',
          backgroundColor: getBackgroundColor(productCards[activeIndex].theme),
          borderRadius: '0px 0px 28px 28px',
        }}
      >
        <ProductCardBody {...productCards[activeIndex]}></ProductCardBody>
      </Flex>
    </Flex>
  );
};

export { MobileVariant };
