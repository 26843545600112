import { ProductCard } from './components/productCard';
import { Grid } from 'theme-ui';
import React from 'react';

import { ProductPricingCardProps } from './types';

const DesktopVariant = ({
  productCards,
}: {
  productCards: ProductPricingCardProps[];
}) => {
  return (
    <Grid
      sx={{ gridGap: '56px', marginX: 'auto', justifyContent: 'center' }}
      columns={productCards.length === 1 ? 1 : [1, 1, 2]}
    >
      {productCards.map(productCard => (
        <ProductCard key={productCard.id} {...productCard} />
      ))}
    </Grid>
  );
};

export { DesktopVariant };
