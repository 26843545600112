import { Box, Flex, Text } from 'theme-ui';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import React from 'react';
import { useBreakpointIndex, useResponsiveValue } from '@theme-ui/match-media';

const Header = ({ icon, title, description, headerText }) => {
  const { iconHeight } = useResponsiveValue(
    [{ iconHeight: '24px' }, { iconHeight: '24px' }, { iconHeight: '40px' }],
    { defaultIndex: 3 },
  );
  const bpIndex = useBreakpointIndex({ defaultIndex: 3 });
  const isMobileOrTablet = bpIndex <= 1;

  const el = (
    <Text
      as="div"
      sx={{
        fontWeight: [600, 600, 700],
        fontSize: ['16px', '16px', '20px'],
        lineHeight: ['22px', '22px', '24px'],
        color: '#107EFF',
        flex: '0 0 auto',
      }}
    >
      {headerText}
    </Text>
  );
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: ['16px', '16px', '24px'],
        justifyContent: ['flex-start', 'flex-start', 'center'],
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          gap: ['16px', '16px', '24px'],
          justifyContent: ['flex-start', 'flex-start', 'center'],
          alignItems: 'center',
        }}
      >
        {icon?.icon ? (
          <Icon
            iconName={icon.icon}
            iconSx={{
              height: `${iconHeight}`,
              color: 'black',
              alignSelf: 'flex-start',
              marginTop: '6px',
            }}
          />
        ) : null}

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            as="div"
            sx={{
              alignSelf: 'center',
              fontWeight: 'bold',
              fontSize: ['24px', '28px', '40px'],
              lineHeight: ['36px', '36px', '52px'],
              color: '#000000',
              flex: '0 0 auto',
            }}
          >
            {title}
          </Text>
          {isMobileOrTablet ? null : el}
        </Box>
      </Flex>
      <Box sx={{ textAlign: 'center' }}>
        {isMobileOrTablet ? el : null}
        <Text
          sx={{
            fontSize: ['16px', '16px', '20px'],
            lineHeight: ['22px', '22px', '24px'],
            fontWeight: 'normal',
            color: '#000000',
          }}
        >
          {description}
        </Text>
      </Box>
    </Flex>
  );
};

export { Header };
