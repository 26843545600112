import React from 'react';
import { Props } from './types';

import { Header } from './components/Header';
import { DesktopVariant } from './DesktopVariant';
import { MobileVariant } from './MobileVariant';

import { Flex } from 'theme-ui';

import { useBreakpointIndex } from '@theme-ui/match-media';

const ProductPricingTemplate = (props: Props) => {
  const { title, subTitle, productPricingCard, icon, headerText } = props;
  const bpIndex = useBreakpointIndex({ defaultIndex: 3 });

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: 23,
        paddingX: '24px',
        paddingY: '32px',
      }}
    >
      <Header
        title={title}
        headerText={headerText}
        description={subTitle}
        icon={icon}
      ></Header>
      {bpIndex === 0 ? (
        <MobileVariant productCards={productPricingCard} />
      ) : (
        <DesktopVariant productCards={productPricingCard} />
      )}
    </Flex>
  );
};

export { ProductPricingTemplate };
