import { CTA } from '@sprinklr/shared-lib/@types/entities';

export const EXCLUSIVE = 'EXCLUSIVE';
type Variant = typeof EXCLUSIVE;

export type FeatureSection = {
  title: string;
  productFeatures: {
    name: string;
    variant: Variant;
  }[];
};

export type ProductPricingCardProps = {
  title: string;
  theme: string;
  featureSection: FeatureSection[];
  cta: CTA;
  id: string;
  pricingInfo: {
    price: string | undefined;
    billingInfo: string;
    enterpriseTitle: string | undefined;
  };
};

export type Props = {
  title: string;
  subTitle: string;
  icon: { icon: string };
  headerText: string;
  productPricingCard: ProductPricingCardProps[];
};
